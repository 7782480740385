// import React from "react";
import ReactGA from "react-ga4";
import ReactDOM from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import './css/index.css';
import { RouterProvider } from 'react-router-dom';

import theme from './theme';
import router from "./router";
import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-231ZXE6SKP");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
      <div className="App">
        <RouterProvider router={router} />
      </div>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
