import axios from "axios";

import { initProductMap } from "./initProductMap";
import { productArray } from "./getDataAllProducts";
import { featuredProductData } from "./getDataFeaturedProductData";

import { IPriceList } from "../type/IPriceList";
import { EnumEndpoint } from "../type/EnumEndpoint";
import { IProduct } from "../type/IProduct";
import { IProductMap } from "../type/IProductMap";
import { IProductHashtagMap } from "../type/IProductHashtagMap";

export interface LoaderMasterResult {
  priceList: IPriceList;
  productArrayWithPrice: IProduct[];
  productMap: IProductMap;
  productHashtagMap: IProductHashtagMap;
  featuredProducts: IProduct[];
}

export default async function loaderMaster() {
  const res = await axios.get<IPriceList>(EnumEndpoint.PRICES).catch((err) => {
    console.log(err);
    return { data: undefined }
  });

  // const res = { data: {} }

  const priceList = res.data;
  const initData = initProductMap(productArray, featuredProductData, priceList);

  return { ...initData, priceList };
}