import { useState } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';

import { LoaderMasterResult } from "../util/loaderMaster";
import { initShoppingCart } from "../util/initShoppingCart";
import { ProductContext, ShoppingCartContext } from "../util/useContext";

function MainContext() {

  const { priceList, productArrayWithPrice, productMap, productHashtagMap, featuredProducts } = useLoaderData() as LoaderMasterResult;
  const [shoppingCartString, setShoppingCart] = useState(initShoppingCart());

  return (
      <ShoppingCartContext.Provider
        value={{
          shoppingCartString,
          setShoppingCart,
        }}
      >
        <ProductContext.Provider
          value={{
            priceList,
            productMap,
            productHashtagMap,
            featuredProducts,
            productArray: productArrayWithPrice,
          }}
        >
          <Outlet />
      </ProductContext.Provider>
    </ShoppingCartContext.Provider >
  );
}

export default MainContext;
