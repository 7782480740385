
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const kamaya: IProductData = {
  id: "mother-kamaya",
  name: "Mother Kamaya",
  description: "Class A Mic Pre inspired by a vintage British classic console preamp.",
  specialOrder: true,
  images: ["/img/mother_kamaya_front.jpg"],
  details: [{
    label: "Description",
    markdown: `
**Kamaya** (meaning "precious one" in Hawaiian) is a Class A circuit microphone preamp inspired by vintage British classic console preamps.
It can deliver up to 62 dB of gain to your microphones, but more importantly,
it gonna breathe life into the sound, adding a new dimension and unique character that is hard to compare with other preamps in the market.<br>
<br>
**TRANSFORMERS**<br>
The input and output of preamp are coupled with our custom ,in-house handmade transformers, of which we are particularly proud.<br>
These, installed in a Kamaya are the result of months of research, experimentation, and testing.
The input transformer is inspired by  the legendary Marinar 10468 (we named it 10568, logically, right?),
but with a higher ratio and tailored to our taste.It truly shapes the sound in a such a way, that Kamaya becomes vibrant right from the input.<br>
<br>
**Gain Control** (more than one , les than five)<br>
A distinctive feature of Kamaya is the presence of three gain controls. The first is attenuation after the input transformer, the second controls gain  through negative feedback, and finally, there is the output attenuation so you can easily control how much you want to saturate first gain stage.
This preamp is literally a gain control beast. By playing with these knobs, you'll be able to shape the sound from lush and smooth to aggressive and almost tube like-saturated.<br>
<br>
**Where will it sound the best?**<br>
After testing it in some of the best EU studios, it seemed that very few classic preamps can sound as good and usable on drums as Kamaya (kick especially).
However, the biggest surprise (given that it's a coloration type of preamp) was the sound of the piano.
It felt as if the natural harmonics of the piano were singing Kamaya's song.Describing Kamaya's sound in words is challenging because
it's simultaneously big and open, yet finely saturated and pushed forward in the lower mids.If you turn Negative Feedback at max , it gonna start to sound aggressive and saturated , but at the same time, there gonna occur a slight roll-off of high frequencies to make the entire sound smoother and more pleasant.
`
  }, {
    label: "Features",
    markdown: `
- Circuit: Class A , discrete
- IN: Transformer balanced
- OUT: Transformer balanced
- MID band: 700 ,1k ,1k5 ,2k3 ,3k5 ,5.6k Bell +-8dB Boost-Cut
- HIGH band: 16kHz Bell , +15dB -9dB, 3dB steps Boost-Cut
- LOW  band: 40Hz Shelf, +15dB -9dB, 3dB steps Boost-Cut
- HPF: -3 dB on 70Hz
- Bypass: True Bypass
`
  }, {
    label: "Technical Specs",
    markdown: `
- Maximum input level: +21 dBu
- Maximum output level: +21 dBu
- Input impedance: 10k
- Output Impedance: Less than 75 Ohms
- Noise: <-85 dBA
- THD: 0.09% at +10dBu
- Max. current draw: 100mA`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.mother-proaudio.com/kamaya" target="_blank">https://www.mother-proaudio.com/kamaya</a>
    `
  }],
  videos: [],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.SINGLE_SLOT, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500, HASHTAGS.MASTERING],
    manufacturer: BRAND.MOTHER,
  }
}