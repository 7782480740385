import { IResource } from "../type/IResource";

import { why500 } from "../data/resources/why500";
import { nextGen500 } from "../data/resources/nextGen500";
import { monitorController } from "../data/resources/monitorControllers";

export const RESOURCES = [monitorController, nextGen500, why500];
export const getResourceById = (sid?: string): IResource | undefined => {
  if (!sid) return undefined;

  const resource = RESOURCES.filter((r) => r.id === sid);

  if (!resource) return undefined;

  return resource[0];
};