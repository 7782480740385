import axios from "axios";
import { LoaderFunctionArgs } from "react-router-dom";

import { EnumEndpoint } from "../type/EnumEndpoint";


export default async function actionSubmitQuote({ request }: LoaderFunctionArgs) {
  const formData = await request.formData();
  const dataString = formData.get("get-quote-data") as string;
  const shoppingCartString = formData.get("get-quote-shoppingcart") as string;

  const data = JSON.parse(dataString);
  data.shoppingCartString = shoppingCartString;

  const result = await axios.post<string>(EnumEndpoint.QUOTE_GEN, data, {
    headers: {
      "Content-Type": "application/json"
    } }).catch((err) => {
    console.log(err);
    return { data: undefined, errMsg: err.response?.data.message || (err.message + ". Please try again later.") }
  });

  if ("errMsg" in result) return result;

  return result;
}