import { createBrowserRouter } from "react-router-dom";

import { PATHS } from './type/EnumPaths';
import MainContext from "./layout/MainContext";
import loaderMaster from './util/loaderMaster';
import loaderResource from './util/loaderResource';
import actionSubmitQuote from "./util/actionSubmitQuote";

export default createBrowserRouter([
  {
    path: "/",
    element: <MainContext />,
    loader: loaderMaster,
    children: [
      {
        index: true,
        lazy: async () => {
          const { Home } = await import("./page/Home");
          return { 
            Component: Home,
          };
        }
      },
      {
        path: PATHS.BRANDS,
        lazy: async () => {
          const { BrandLayout } = await import("./layout/BrandLayout");
          return {
            Component: BrandLayout,
          }
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { Brands } = await import("./page/Brands");
              return {
                Component: Brands,
              }
            }
          }, {
            path: ":id",
            lazy: async () => {
              const { Brand } = await import("./page/Brand");
              return {
                Component: Brand,
              }
            }
          }
        ]
      },
      {
        path: PATHS.PRODUCTS,
        lazy: async () => {
          const { ProductLayout } = await import("./layout/ProductLayout");
          return {
            Component: ProductLayout,
          }
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { Products } = await import("./page/Products");
              return {
                Component: Products,
              }
            }
          }, {
            path: ":id",
            lazy: async () => {
              const { Product } = await import("./page/Product");
              return {
                Component: Product,
              }
            }
          }
        ]
      },
      {
        path: PATHS.RESOURCES,
        lazy: async () => {
          const { ResourceLayout } = await import("./layout/ResourceLayout");
          return {
            Component: ResourceLayout,
          }
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { Resources } = await import("./page/Resources");
              return {
                Component: Resources,
              }
            }
          },
          {
            path: ":id",
            loader: loaderResource,
            lazy: async () => {
              const { Resource } = await import("./page/Resource");
              return {
                Component: Resource,
              }
            },
          }
        ]
      },
      {
        path: PATHS.CHECKOUT,
        children: [
          {
            index: true,
            lazy: async () => {
              const { CheckoutSummary } = await import("./page/CheckoutSummary");
              return {
                Component: CheckoutSummary,
              }
            },
          },
          {
            path: PATHS.QUOTE,
            action: actionSubmitQuote,
            lazy: async () => {
              const { CheckoutQuote } = await import("./page/CheckoutQuote");
              return {
                Component: CheckoutQuote,
              }
            }
          }
        ]
      },
      {
        path: PATHS.ABOUT,
        lazy: async () => {
          const { About } = await import("./page/About");
          return {
            Component: About,
          }
        }
      },
      {
        path: PATHS.FAQ,
        lazy: async () => {
          const { FAQ } = await import("./page/FAQ");
          return {
            Component: FAQ,
          }
        }
      },
      {
        path: "*",
        lazy: async () => {
          const { NotFound } = await import("./page/NotFound");
          return {
            Component: NotFound,
          }
        }
      }
    ]
  }
])