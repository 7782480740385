import { IProduct } from "../type/IProduct";
import { IPriceList } from "../type/IPriceList";
import { IProductMap } from "../type/IProductMap";
import { IProductBase, IProductData } from "../type/IProductData";
import { IProductHashtagMap } from "../type/IProductHashtagMap";

const setHashtag = (p: IProductData, productHashtagMap: IProductHashtagMap, value: IProduct) => {
  if (p.metadata.hashtags) {
    p.metadata.hashtags.forEach((hashtag) => {
      if (productHashtagMap[hashtag] === undefined) productHashtagMap[hashtag] = {};
      productHashtagMap[hashtag][p.id] = value;
    });
  }
  return productHashtagMap;
}

const setFav = (featuredProducts: IProductData[], priceList?: IPriceList) => {
  if (!priceList || Object.keys(priceList).length === 0) return featuredProducts.map((p) => ({ ...p, price: { price: "", cardPrice: "", pid: p.id } }));
  return featuredProducts.map((p) => ({ ...p, price: priceList[p.id] }));
}

interface initProdMap { productArrayWithPrice: IProduct[], productMap: IProductMap, productHashtagMap: IProductHashtagMap, featuredProducts: IProduct[] }

export const initProductMap = (allProducts: IProductData[], featuredProducts: IProductData[], priceList?: IPriceList): initProdMap => {  
  if (!priceList || Object.keys(priceList).length === 0) return allProducts.reduce((prev, p: IProductBase) => {
    const emptyProd = {
      ...p,
      price: {
        price: "",
        cardPrice: "",
        pid: p.id,
      }
    }
    prev.productArrayWithPrice.push(emptyProd);
    prev.productMap[p.id] = emptyProd;
    setHashtag(p, prev.productHashtagMap, emptyProd);
    return prev;
  }, { productArrayWithPrice: [], productMap: {}, productHashtagMap: {}, featuredProducts: setFav(featuredProducts, priceList) } as initProdMap);
  
  return allProducts.reduce((prev, p: IProductBase) => {
    const prod = {
      ...p,
      price: priceList[p.id]
    };
    prev.productMap[p.id] = prod;
    prev.productArrayWithPrice.push(prod);
    setHashtag(p, prev.productHashtagMap, prod);
    return prev;
  }, { productArrayWithPrice: [], productMap: {}, productHashtagMap: {}, featuredProducts: setFav(featuredProducts, priceList) } as initProdMap);
}