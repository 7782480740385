// import { bettermakerEQ542 } from "../data/products/bettermakerEQ542";
import { sixpack } from "../data/products/radial/radialSixpack";
import { radialWorkhorse } from "../data/products/radial/radialWorkhorse";
import { radialWorkhorseWMixer } from "../data/products/radial/radialWorkhorseWMixer";
// import { waTB12 } from "../data/products/warmAudio/WATB12-500";
import { wa12MKII } from "../data/products/warmAudio/WA12MIKII-500";
// import { cranborne500R8 } from "../data/products/cranborne500R8";
// import { shadowhillsDualVandergraph } from "../data/products/shadowhillsDualVandergraph";
import { ssl500sixchannel } from "../data/products/ssl/ssl500sixchannel";
import { sslGComp } from "../data/products/ssl/sslGComp";
import { germ500MKII } from "../data/products/chandlerLimited/germ500MKII";
import { TG2500 } from "../data/products/chandlerLimited/TG2500";
// import { Atlas } from "../data/products/prismSound/Atlas";
// import { Lyra2 } from "../data/products/prismSound/Lyra2";
// import { catapult } from "../data/products/radial/catapult";
// import { catapultMiniRX } from "../data/products/radial/catapultMiniRX";
import { rhea } from "../data/products/wesaudio/rhea";
import { dione } from "../data/products/wesaudio/dione";
import { prometheus } from "../data/products/wesaudio/prometheus";
import { hyperion } from "../data/products/wesaudio/hyperion";
import { calypso } from "../data/products/wesaudio/calypso";
import { mimas } from "../data/products/wesaudio/mimas";
import { titan } from "../data/products/wesaudio/titan";
import { phoebe } from "../data/products/wesaudio/phoebe";
import { ngbuscomp } from "../data/products/wesaudio/ngbuscomp";
import { ngleveler } from "../data/products/wesaudio/ngleveler";
import { dioneStarlight } from "../data/products/wesaudio/dioneStarlight";
import { beta76 } from "../data/products/wesaudio/beta76";
import { timbre } from "../data/products/wesaudio/timbre";
import { supercarrierII } from "../data/products/wesaudio/supercarrierII";
import { odd } from "../data/products/purpleAudio/5E1Odd";
import { action } from "../data/products/purpleAudio/5C1Action";
import { tav } from "../data/products/purpleAudio/5E2Tav";
import { lilpeqrM } from "../data/products/purpleAudio/5E3LilpeqrM";
import { mc77 } from "../data/products/purpleAudio/mc77";
import { biz } from "../data/products/purpleAudio/5M1Biz";
import { pants } from "../data/products/purpleAudio/5M2Pants";
import { cansII } from "../data/products/purpleAudio/5H1CansII";
import { moiyn } from "../data/products/purpleAudio/5S1Moiyn";
import { sweetTenRack } from "../data/products/purpleAudio/sweetTen";
import { ng76 } from "../data/products/wesaudio/ng76";
import { ng76StereoPair } from "../data/products/wesaudio/ng76Stereo";
import { mixingBundle } from "../data/products/wesaudio/mixingBundle";
import { recordingMixingBundle } from "../data/products/wesaudio/recordingMixingBundle";
import { distressorel8x } from "../data/products/empiricalLabs/distressorel8x";
import { distressorel8xPair } from "../data/products/empiricalLabs/distressorel8xPair";
import { distressorel8 } from "../data/products/empiricalLabs/distressorel8";
import { distressorel8Pair } from "../data/products/empiricalLabs/distressorel8Pair";
import { bigFreq } from "../data/products/empiricalLabs/freEQ";
import { arousor } from "../data/products/empiricalLabs/arousor";
import { mikeE } from "../data/products/empiricalLabs/el9MikeE";
import { el9MikeETwinPak } from "../data/products/empiricalLabs/el9MikeETwinPak";
import { lilFrEQ } from "../data/products/empiricalLabs/elqLilFrEq";
import { trakPak } from "../data/products/empiricalLabs/trakPak";
import { elx7Fatso } from "../data/products/empiricalLabs/elx7Fatso";
import { derrEsserH } from "../data/products/empiricalLabs/derrEsserH";
import { derrEsser } from "../data/products/empiricalLabs/derrEsser";
import { docDerr } from "../data/products/empiricalLabs/docDerr";
import { docDerrH } from "../data/products/empiricalLabs/docDerrH";
import { creme } from "../data/products/tegeler/creme";
import { eqp1 } from "../data/products/tegeler/eqp1";
import { cremeRC } from "../data/products/tegeler/cremeRC";
import { magnetismus2 } from "../data/products/tegeler/magnetismus2";
import { raumzeitmaschine } from "../data/products/tegeler/raumzeitmaschine";
import { schwerkraftmaschine } from "../data/products/tegeler/schwerkraftmaschine";
import { tubesummingmixer } from "../data/products/tegeler/tubesummingmixer";
import { varitubecompressor } from "../data/products/tegeler/varitubecompressor";
import { varituberecordingchannel } from "../data/products/tegeler/varituberecordingchannel";
import { vocalleveler500 } from "../data/products/tegeler/vocalleveler500";
import { mytheq500 } from "../data/products/tegeler/mytheq500";
import { hyperionEclipse } from "../data/products/wesaudio/hyperionEclipse";
import { prometheusEclipse } from "../data/products/wesaudio/prometheusEclipse";
import { vivid } from "../data/products/mother/vivid";
import { wai2950 } from "../data/products/mother/wai2950";
import { kamaya } from "../data/products/mother/kamaya";
import { pump } from "../data/products/empiricalLabs/pump";
import { mythvca500 } from "../data/products/tegeler/mythvca500";
import { ngTubeEG } from "../data/products/wesaudio/ngTubeEQ";

export const productArray = [
  vivid,
  wai2950,
  kamaya,
  pump,
  mythvca500,
  phoebe,
  rhea,
  dione,
  dioneStarlight,
  prometheus,
  prometheusEclipse,
  hyperion,
  hyperionEclipse,
  mimas,
  ngbuscomp,
  ngTubeEG,
  ng76,
  ng76StereoPair,
  beta76,
  timbre,
  calypso,
  mixingBundle,
  recordingMixingBundle,
  cremeRC,
  creme,
  eqp1,
  schwerkraftmaschine,
  raumzeitmaschine,
  magnetismus2,
  vocalleveler500,
  mytheq500,
  biz,
  pants,
  odd,
  tav,
  lilpeqrM,
  action,
  mc77,
  cansII,
  moiyn,
  sweetTenRack,
  titan,
  distressorel8x,
  distressorel8xPair,
  distressorel8,
  distressorel8Pair,
  derrEsser,
  derrEsserH,
  docDerr,
  docDerrH,
  mikeE,
  el9MikeETwinPak,
  lilFrEQ,
  elx7Fatso,
  trakPak,
  arousor,
  bigFreq,
  ngleveler,
  supercarrierII,
  wa12MKII,
  varitubecompressor,
  varituberecordingchannel,
  tubesummingmixer,
  // waTB12,
  ssl500sixchannel,
  sslGComp,
  germ500MKII,
  TG2500,
  sixpack,
  radialWorkhorse,
  radialWorkhorseWMixer,
  // Atlas,
  // Lyra2,
  // catapult,
  // catapultMiniRX,
  // cranborne500R8, 
  // shadowhillsDualVandergraph, 
  // bettermakerEQ542,
];

export const numOfProducts = productArray.length;