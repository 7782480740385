import { BRAND } from "../../../type/EnumBrand";
import { HASHTAGS } from "../../../type/EnumHashtags";
import { IProductData } from "../../../type/IProductData";

export const ngTubeEG: IProductData = {
  id: "wesaudio-ngtubeeq",
  name: "WesAudio NGTUBEEQ",
  description: "Next Gen Tube Equalizer",
  images: ["/img/wesaudio_ngtubeeq_front.png", "/img/wesaudio_ngtubeeq_inside.jpg", "/img/wesaudio_ngtubeeq_back.png"],
  details: [{
    label: "Description",
    markdown: `
For decades, tube equalizers have been cherished for their rich, musical sound, shaping the sonic character of countless classic recordings.  
Our ngTubeEQ elevates this legacy, offering a refined, analog experience seamlessly integrated into the modern digital workflow.  
With its lush tonal quality and intuitive digital control, the ngTubeEQ is set to become an indispensable tool in studios worldwide, continuing our tradition of blending vintage charm with contemporary functionality. 
Experience the best of both worlds – the soul of vintage sound and the ease of digital technology with ngTubeEQ.
`
  }, {
    label: "Key Features",
    markdown: `
### Main Features
- 100% Analog Parametric Tube Equalizer: The ngTubeEQ offers pure analog processing with a substantial 26dBu headroom.
- Custom Passive Section: At its core, the ngTubeEQ features a passive section built around custom inductors for exceptional sound quality.
- Four Passive Bands with Extensive Range: Boasting 12 selectable frequency points per band, covering from 22Hz to 28kHz.
- Bypassable Passive Bands with Variable Q: Each band is independently bypassable and includes a fully variable Q for nuanced sound sculpting.
- Versatile Active Filters: Active LPF & HPF filters with 12/24 dB per octave slopes, variable controls, and analog bypass switch.
- Three Operational Modes: Offering dual, stereo, and mid-side operational modes for flexible sound staging.
- Comprehensive DAW Plugin Control: Full control and instant recall through an efficient GUI in all plugin formats, compatible with PC/MAC across all DAWs.
- Dual Output Options: GREEN for electronically balanced output, RED for a tube amplification stage with transformer output and IRON PAD.
- THD with Proprietary Circuitry: Features a unique harmonic distortion circuit, fully bypassable, that integrates with the gain structure for adjustable inductor-based saturation.
- Unique Q Settings: Proportional and Constant Q options, making it a rare EQ with both constant and variable Q implementations.
- Flexible Gain Modes: Two gain modes – 15dB (±15dB range, 0.25dB step) and 5dB (±5dB range, 0.1dB step).
- Band Listen Mode to isolate and focus on band fundamental content via LPF and HPF adjustment.
- Capability to store up to 100 presets on the device’s internal memory for operation without plugin integration.
- Integrated LCD Parameter Display: A compact LCD screen that visualizes metering and parameter changes upon knob interaction.
- Comprehensive Metering: Integrated LCD and plugin metering for detailed input and output monitoring.
- Band Listen Mode to isolate and focus on band fundamental content via LPF and HPF adjustment.
- Hardware A/B/C Memory Settings: Easily switch between three analog preset settings using simple buttons, even when using the ngTubeEQ as a pure analog processor.
- USB and Ethernet Connectivity: Supports direct USB connection or network integration via Ethernet for versatile setup options.
- Capability to store up to 100 presets on the device’s internal memory for operation without plugin integration.
- Analog Automation Capability: Draw automation lines in your DAW to control the analog device settings dynamically, with touch-sensitive encoders for recording automation back to your DAW.
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response Normal (green)	5Hz-100kHz (-0.5dB)
- Frequency response Tube (red)	20Hz-25kHz (0.5dB)
- Dynamic range	122dB
- THD+N Normal(green)	0.0025% (+4dBu), 0.009% (+18dBu)
- THD+N Tube(red)	0.04% (+4dBu), 0.016% (+18dBu)
- Noise	-93dBu (A-weight)
- Crosstalk	-120dB (40Hz), -100dB (20kHz)
- Max input level	+28dBu
- Max output level	+27dBu
- Input impedance	44kohm
- Common mode rejection ratio	70dB
- Output impedance Normal (green)	100ohm
- Output impedance Tube (red)	150ohm
- Dimensions	135x483x252mm
- Box dimensions	274x550x382mm
- Unit weight	8,8 kgs
- Box weight	11,5 kgs
- Power consumption (MAX)	45W
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ngtubeeq/" target="_blank">https://wesaudio.com/product/ngtubeeq/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.THREE_U, HASHTAGS.RACK],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/RRuW3MOvtFc?si=_7FSxo3p-nfcYfRJ",
  ]
}